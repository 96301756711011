import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import { Link } from 'gatsby';

import Icon from '../Icon';
import Card from './Card';

class CarCard extends React.PureComponent {
  render() {
    const { name, images, description, isSold, price } = this.props;
    return (
      <Card>
        <Card.ImageContainer>
          {isSold && (
            <Card.Sold>
              <span>Sold</span>
            </Card.Sold>
          )}
          <Card.Image sizes={images[0].sizes} alt={name} />
        </Card.ImageContainer>
        <Card.Meta>
          <Card.Title>{name}</Card.Title>
          {!isSold && <Card.Price>{numeral(price).format('$0,0')}</Card.Price>}
          <Card.Description>{description}</Card.Description>
          <Card.LearnMoreButton>
            Learn More <Icon name="angle-right" />
          </Card.LearnMoreButton>
        </Card.Meta>
      </Card>
    );
  }
}

CarCard.propTypes = {
  name: PropTypes.string.isRequired,
  images: PropTypes.array.isRequired,
  description: PropTypes.string.isRequired,
  isSold: PropTypes.bool.isRequired,
  price: PropTypes.number.isRequired,
  slug: PropTypes.string.isRequired,
};

export default CarCard;
