import styled from 'styled-components';

import { devices } from '../../styles';
import Image from '../image';

const ImageContainer = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: ${({ theme }) => theme.background.secondary};
`;

const Meta = styled.div`
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  position: relative;
  padding: 32px;
`;

const Title = styled.h4`
  margin: 0;
  font-size: 24px;
  line-height: 30px;
  font-weight: bold;
  padding-bottom: 8px;
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding-bottom: 8px;
`;

const CardImage = styled(Image)`
  z-index: 0;
`;

const Sold = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  color: ${({ theme }) => theme.text.sold};
  z-index: 10;
  text-transform: uppercase;
  background: linear-gradient(
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0.3)
  );
  font-weight: bold;
  font-size: 0.8rem;
  text-align: right;

  & > span {
    position: relative;
    right: 10px;
    display: inline-block;
    background: rgba(255, 255, 255, 0.9);
    text-align: center;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    padding: 3px 6px;
  }
`;

const Price = styled.div`
  font-size: 24px;
  line-height: 30px;
  padding-bottom: 12px;
`;

const LearnMoreButton = styled.div`
  text-align: right;
  font-size: 0.9rem;
  color: ${({ theme }) => theme.text.link};
`;

const Card = styled.div`
  width: 400px;
  margin-bottom: 48px;
  transform: scale(1);
  transition: transform 0.18s ease-in-out, box-shadow 0.18s ease-in-out;
  border-radius: 6px;
  color: ${({ theme }) => theme.text.standard};
  text-decoration: none;
  background-color: ${({ theme }) => theme.background.tertiary};

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0 2px 20px 0;
    transform: scale(1.03);
  }

  @media ${devices.mobile} {
    width: 100%;
  }
`;

Card.ImageContainer = ImageContainer;
Card.Meta = Meta;
Card.Title = Title;
Card.Description = Description;
Card.Image = CardImage;
Card.Sold = Sold;
Card.Price = Price;
Card.LearnMoreButton = LearnMoreButton;

export default Card;
